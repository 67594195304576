<template>
  <section :class="$style.transactions">
    <SubHeader :userId="$route.params.id" />

    <h2 :class="$style.name">Всего бонусов: {{ this.totalCount }}</h2>
    <el-table :data="transactions" stripe>
      <el-table-column prop="createdAt" label="Дата создания">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.createdAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="Тип">
        <template slot-scope="scope">
          {{ scope.row.value > 0 ? 'Начисление' : 'Списание' }}
        </template>
      </el-table-column>
      <el-table-column prop="value" label="Значение" />
      <el-table-column prop="coefficient" label="Коэффициент" />
      <el-table-column prop="comment" label="Комментарий" />
      <el-table-column prop="expiresAt" label="Дата сгорания">
        <template slot-scope="scope">
          {{ formatDateTime(scope.row.expiresAt) }}
          <input
            v-if="scope.row.expiresAt"
            type="checkbox"
            :checked="new Date(scope.row.expiresAt) > Date.now()"
            style="pointer-events: none; margin-left: 0.5rem"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import SubHeader from '@/components/atoms/SubHeader.vue'
import { formatDateTime } from '@/helpers/index.js'

export default {
  components: { SubHeader },
  data() {
    return {
      transactions: [],
      limit: 20,
      page: 1,
      total: 10,
      totalCount: 0,
    }
  },
  created() {
    this.getList()
    this.getCount()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      const { value, error } =
        await delivery.AccountsCore.BonusTransactionsActions.getList(
          this.$route.params.id,
          query,
        )
      loading.close()

      if (error) {
        return
      }

      this.transactions = value.data
      this.total = value.meta.count
    },

    async getCount() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.AccountsCore.BonusTransactionsActions.getCount(
          this.$route.params.id,
        )
      loading.close()

      if (error) {
        return
      }

      this.totalCount = value
    },
    formatDateTime(date) {
      return date == null ? '' : formatDateTime(date)
    },
  },
}
</script>

<style lang="scss" module>
.transactions {
  padding: 1rem;
  .pagination {
    @include pagination;
  }
}
.name {
  margin-bottom: 1rem;
  margin-left: 1.25rem;
}
</style>
